import React from 'react'
import css from './math-fields.module.css'
import { Link } from 'gatsby';

export const MathFields = ({ mathFields }) => (
    <ul id="math-fields" className={css.wrapper}>
        {mathFields.map(field => <li className={css.item}>
            <h2 className={css.itemHeader}>{field.name}</h2>
            <ul className={css.sublist}>{field.list.map(subfield => <li>
                <Link to={`${field.slug}/${subfield.slug}`} className={css.subitemHeader}>{subfield.name}</Link>
            </li>)}
            </ul>
        </li>)}
    </ul>
)
