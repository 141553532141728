import React from "react"
import SEO from "../components/seo"
import { Header } from "../components/header"
import { graphql } from "gatsby"
import { MathFields } from "../components/math-fields"
import { MainCopy } from "../components/main-copy"
import '../base.css';
import css from './index.module.css'
import { Footer } from "../components/footer"

const IndexPage = ({ data: { allMathFieldsYaml: { nodes: mathFields } } }) => (
  <>
    <SEO title="Free Math Resources - Textbooks, Lectures Notes, Videos and Online Courses" />
    <Header />
    <h2 id="top" className={css.header}>Discover Incredible FREE Resources to Study Mathematics:</h2>
    <MathFields mathFields={mathFields} />
    <MainCopy />
    <Footer />
  </>
)

export default IndexPage

export const pageQuery = graphql`
query {
  allMathFieldsYaml {
    nodes {
      name
      slug
      list {
        name
        slug
      }
    }
  }
}
`;
