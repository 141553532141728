import React from 'react'
import css from './main-copy.module.css'
import image from '../images/class.jpg'
import { AnchorLink } from 'gatsby-plugin-anchor-links';

export const MainCopy = () => {
  return (
    <>
      <div id="about" className={css.wrapper}>
        <div className={css.imageContainer}><img className={css.image} src={image} alt="" /></div>
        <div className={css.copy}>
            <p>Web is filled with great, free mathematics resources. It's just a matter of finding them.</p>
            <p><span className={css.siteName}>Real <span className={css.notComplex}>Not Complex</span></span> is a curated list of free math textbooks, lecture notes, videos and more.</p>
            <p>Simply choose the topic that interests you and start studying!</p>
            <AnchorLink to="/#top" className={css.topicsLink}>See the topics</AnchorLink>
        </div>
      </div>
    </>
  )
};
